import React from "react";
import { SEO, PageLayout, Image, FoodMenu } from "@bluefin/components";
import { Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ShopPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCatalogSchedule,
      allFishermanBusinessMenuCatalogCategory,
      allFishermanBusinessMenuCatalogItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"shop-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <div className={"shop-products-container"}>
            <Header
              as={"h1"}
              className={"shop-header"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "shop_header",
                defaultValue: "Shop",
              })}
            />
            <FoodMenu
              header={""}
              showCategoryDropdown={false}
              showMenuLikes={false}
              centerNavigation={true}
              categoryDisplayVariant={"VerticalCardRow"}
              itemCartModalVariant={"CatalogItemLightbox"}
              disableModifiers={true}
              disableDescription={false}
              menu={{
                schedules: allFishermanBusinessMenuCatalogSchedule.nodes,
                categories: allFishermanBusinessMenuCatalogCategory.nodes,
                items: allFishermanBusinessMenuCatalogItem.nodes,
              }}
              business={{
                id: fishermanBusiness._id,
                type: fishermanBusiness.type,
              }}
              cart={true}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Shop" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessMenuCatalogSchedule(
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCatalogCategory(
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuCatalogItem(sort: { order: ASC, fields: order }) {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        visible
        available
      }
    }
  }
`;
